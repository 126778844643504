import React from "react"
import {
  IconExternal,
  PreStyledComponents,
  Link
} from "@life-without-barriers/react-components"
import { sendEvent } from "@life-without-barriers/utilities"

const { IconWrapper } = PreStyledComponents

function eventTracking(
  eventCategory: string,
  eventAction = "Click",
  eventLabel: string
) {
  sendEvent("lwb.ga", eventCategory, eventAction, eventLabel)
}

export interface ContactUsTileProps {
  ga: { eventCategory: string; eventAction?: string; eventLabel: string }
  icon: JSX.Element
  text: string
  link: string
  externalLink?: boolean
}

export const PhoneNumberTile = (props: ContactUsTileProps) => {
  const ga = props.ga
  return (
    <div className="flex flex-row mt3 mv4-l copy">
      <div className="flex items-center">
        <IconWrapper>{props.icon}</IconWrapper>
        <div className="pl3">
          <Link
            onClick={() =>
              eventTracking(ga.eventCategory, ga.eventAction, ga.eventLabel)
            }
            className="link color-lwb-black fw3 underline no-underline-ns"
            to={props.link}
            title="Phone 1300592227"
            aria-label="Phone 1 3 0 0 5 9 2 2 2 7."
            itemProp="telephone"
          >
            {props.text}
          </Link>
        </div>
      </div>
    </div>
  )
}

export const ContactUsTile = (props: ContactUsTileProps) => {
  const ga = props.ga
  const link = (
    <Link
      onClick={() =>
        eventTracking(ga.eventCategory, ga.eventAction, ga.eventLabel)
      }
      className="link color-lwb-black fw5"
      to={props.link}
    >
      {props.text}
      {props.externalLink ? (
        <span className="pl1">
          <IconExternal height="10" width="10" />
        </span>
      ) : null}
    </Link>
  )

  return (
    <div className="t-tile flex flex-row mt3 mv4-l copy">
      <div className="flex items-center">
        <IconWrapper>{props.icon}</IconWrapper>
        <div className="pl3 underline">{link}</div>
      </div>
    </div>
  )
}

export default ContactUsTile
