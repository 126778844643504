import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

export const BackgroundImageGradient = styled(GatsbyImage)`
  top: 0;
  right: 0;

  :before,
  :after {
    content: "";
    z-index: 1;
    top: 0;
    bottom: 0;
    width: 20%;
    position: absolute;
  }

  &:before {
    left: 0;
    background: linear-gradient(
      to right,
      rgba(246, 246, 246, 1),
      rgba(246, 246, 246, 0)
    );
  }

  &:after {
    right: 0;
    background: linear-gradient(
      to left,
      rgba(246, 246, 246, 1),
      rgba(246, 246, 246, 0)
    );
  }
`
