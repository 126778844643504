import React from "react"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import {
  PreStyledComponents,
  Link,
  Variables,
  Helmet,
  BannerFeatured,
  IconFamily,
  IconArrowRight,
  IconKinship,
  IconTick,
  IconPhone,
  IconAt,
  IconFacebook,
  IconTwitter,
  IconInstagram,
  IconClipboard,
  TestNavigationTiles,
  Section,
  Container,
  Row,
  Box,
  Heading,
  StandardButton
} from "@life-without-barriers/react-components"
import {
  getGatsbyImage,
  contentTypes as ct
} from "@life-without-barriers/gatsby-common"

import {
  LearnAboutFosterCare,
  CanIBeACarer,
  TypesOfFosterCare
} from "../../components/foster-care/RelatedPagesContent"
import Layout from "../../components/foster-care/Layout"
import { FosterCareForm } from "@life-without-barriers/shared-contact-form"
import ContactUsTile, {
  PhoneNumberTile
} from "../../components/foster-care/ContactUsTile/ContactUsTile"
import { BackgroundImageGradient } from "../../components/foster-care/LocalStyledComponents"
import { pathFromContentfulSlug } from "../../contentTypes"

const { youthPinkMedium, youthPinkXXXLight } = Variables

const { IconWrapper, Container: ContainerNoGrid } = PreStyledComponents

interface Props {
  location: { pathname: string }
  data: {
    whyBeACarerImage: IGatsbyImageData
    featuredStory: ct.ContentNodes<
      Pick<ct.FosterCareStoryFull, "slug" | "title" | "bannerImage">
    >
    site: ct.Site
  }
}

interface FeaturedBannerForExperimentProps {
  image: IGatsbyImageData
  alt: string
  name: string
  title: string
  subtitle: string
  slug: string
  buttonText: string
}

const FeaturedBanner = ({
  image,
  alt,
  name,
  title,
  subtitle,
  slug,
  buttonText
}: FeaturedBannerForExperimentProps) => {
  return (
    <BannerFeatured image={image} alt={alt}>
      <div className="flex-ns justify-end-ns pt5 pt6-l pb5">
        <div className="w-100 w-50-l featured-banner-content tc tl-l">
          <div className="mw6-l">
            <h1 className="banner-title ma0 ttu vera-mono color-lwb-white">
              {title}
            </h1>
            <p className="fw8 lh-copy copy-lrg">
              <span className="dn di-ns">{"- "}</span>
              {name}
              <span className="dn di-ns">, {subtitle}</span>
            </p>
          </div>
          <div className="mt4 mt5-ns">
            <Link to={pathFromContentfulSlug(slug)} title={buttonText}>
              <StandardButton className="w-100 w-auto-ns mr3-ns" shade="white">
                {buttonText}
              </StandardButton>
            </Link>
            <Link to="/foster-care/stories" title="See All Stories">
              <StandardButton
                className="w-100 w-auto-ns mt3 mt0-ns"
                shade="xdark"
              >
                See All Stories
              </StandardButton>
            </Link>
          </div>
        </div>
      </div>
    </BannerFeatured>
  )
}

const iconSelector = (
  type: string,
  color: string,
  height?: string
): JSX.Element | undefined => {
  if (type === "family") {
    return <IconFamily color={color} height={height} />
  }
  if (type === "clipboard") {
    return <IconClipboard color={color} height={height} />
  }
  if (type === "kinship") {
    return <IconKinship color={color} height={height} />
  }
  return undefined
}

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    featuredStory,
    whyBeACarerImage
  }
}: Props) => {
  const featureStoryNode = featuredStory.edges[0].node

  return (
    <Layout>
      <Helmet
        title={`Foster care - We put children first | ${siteMetadata.title}`}
        description="We are urgently seeking foster carers all across Australia. Can you open your heart and your home to a child in need? There are different types of foster care that can suit you. We offer training and 24/7 support."
        image={featureStoryNode.bannerImage.gatsbyImageData}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        includeGoogleSchema
      />
      <FeaturedBanner
        image={featureStoryNode.bannerImage.gatsbyImageData}
        alt="Same-sex female foster carer couple in their forties in the nursery room smiling"
        name={featureStoryNode.title}
        title={`"We wanted to give back to the community in some way."`}
        subtitle="long and Short-term foster carers"
        slug={featureStoryNode.slug}
        buttonText="Read Their Story"
      />
      <Section background="bg-hex-image">
        <Container>
          <TestNavigationTiles
            selectIconFn={iconSelector}
            pages={[LearnAboutFosterCare, CanIBeACarer, TypesOfFosterCare]}
            currentPathname={location.pathname}
            heading={
              <Heading size={2} className="tc color-lwb-theme-medium">
                New to foster care? Start your journey here
              </Heading>
            }
          />
        </Container>
      </Section>
      <Section background="bg-lwb-grey-xxx-light">
        <div className="ph3 ph4-ns">
          <ContainerNoGrid className="pv4 pv5-ns relative overflow-hidden">
            <div className="w-100 w-50-l ma0 mb5-l relative z-2">
              <Heading size={2} className="color-lwb-theme-medium">
                Why be a carer with Life Without Barriers?
              </Heading>
              <ul className="pa0 list copy-lrg">
                <li className="mt4 flex items-center">
                  <IconWrapper
                    className="IconWrapper mr3"
                    size="30"
                    color={youthPinkXXXLight}
                  >
                    <IconTick height="13" color={youthPinkMedium} />
                  </IconWrapper>
                  <span className="w-80 w-100-ns ">
                    Our foster carers receive extensive training and 24/7
                    support to navigate their foster care journey.
                  </span>
                </li>
                <li className="mt3 flex items-center">
                  <IconWrapper
                    className="IconWrapper mr3"
                    size="30"
                    color={youthPinkXXXLight}
                  >
                    <IconTick height="13" color={youthPinkMedium} />
                  </IconWrapper>
                  <span className="w-80 w-100-ns">
                    We put the needs of the child and carers first to help them
                    thrive.
                  </span>
                </li>
                <li className="mt3 flex items-center">
                  <IconWrapper
                    className="IconWrapper mr3"
                    size="30"
                    color={youthPinkXXXLight}
                  >
                    <IconTick height="13" color={youthPinkMedium} />
                  </IconWrapper>
                  <span className="w-80 w-100-ns">
                    We value a child&apos;s connection to culture, family, and
                    community.
                  </span>
                </li>
                <li className="mt3 flex items-center">
                  <IconWrapper
                    className="IconWrapper mr3"
                    size="30"
                    color={youthPinkXXXLight}
                  >
                    <IconTick height="13" color={youthPinkMedium} />
                  </IconWrapper>
                  <span className="w-80 w-100-ns">
                    We welcome carers and families in all shapes and sizes from
                    all walks of life.
                  </span>
                </li>
              </ul>
              <Link
                to="/foster-care/carer-support"
                className="link db mt3 mt4-ns"
              >
                Learn more about carer support
                <IconArrowRight
                  height="8"
                  width="5"
                  color={youthPinkMedium}
                  className="ml2"
                />
              </Link>
            </div>
            <div className="dn db-l absolute top-0 right-2 z-0">
              <BackgroundImageGradient
                imgStyle={{
                  objectPosition: "top center"
                }}
                image={getGatsbyImage(whyBeACarerImage)}
                alt="Happy smiling toddler girl"
              />
            </div>
          </ContainerNoGrid>
        </div>
      </Section>
      <Container>
        <Row className="flex-wrap">
          <Box className="w-100 w-seven-twelfths-l layout-gap">
            <h2 className="title ts-display-3 fw8">Get in touch today</h2>
            <p className="copy copy-lrg">
              Our friendly and helpful foster care specialists are ready to
              answer your questions. Whether you&apos;re looking to start the
              process, or just want more info, our team can help.
            </p>
            <div className="pt3 w-90 copy copy-lrg">
              You can contact us via social channels, email and telephone. Our
              team will come back to you as soon as possible.
            </div>
            <div className="mt2 flex flex-row flex-wrap">
              <div className="w-100 w-33-l">
                <PhoneNumberTile
                  ga={{ eventCategory: "Phone number", eventLabel: "Home" }}
                  icon={<IconPhone height="26" color={youthPinkMedium} />}
                  text="1300 592 227"
                  link="tel:1300592227"
                />
                <ContactUsTile
                  ga={{ eventCategory: "Email link", eventLabel: "Home" }}
                  icon={<IconAt height="26" color={youthPinkMedium} />}
                  text="carers@lwb.org.au"
                  link="mailto:carers@lwb.org.au"
                />
              </div>
              <div className="w-100 w-33-l ph3-l">
                <ContactUsTile
                  ga={{ eventCategory: "Facebook link", eventLabel: "Home" }}
                  icon={<IconFacebook height="20" color={youthPinkMedium} />}
                  text="Facebook"
                  link="https://www.facebook.com/LWBAustralia"
                  externalLink
                />
                <ContactUsTile
                  ga={{ eventCategory: "Twitter link", eventLabel: "Home" }}
                  icon={<IconTwitter height="18" color={youthPinkMedium} />}
                  text="Twitter"
                  link="https://twitter.com/LWBAustralia"
                  externalLink
                />
              </div>
              <div className="w-100 w-33-l ph3-l">
                <ContactUsTile
                  ga={{ eventCategory: "Instagram link", eventLabel: "Home" }}
                  icon={<IconInstagram height="20" color={youthPinkMedium} />}
                  text="Instagram"
                  link="https://instagram.com/lwbaustralia"
                  externalLink
                />
              </div>
            </div>
          </Box>
          <Box className="w-100 w-third-l mt4 mt0-l">
            <FosterCareForm formId="foster-care-home" />
          </Box>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    featuredStory: allContentfulFosterCareStory(
      filter: { title: { eq: "Kaz and Jo" } }
    ) {
      edges {
        node {
          slug
          title
          bannerImage {
            ...ContentfulAsset
          }
        }
      }
    }
    whyBeACarerImage: file(relativePath: { regex: "/smiling-child.jpg/" }) {
      childImageSharp {
        gatsbyImageData(height: 600, quality: 90)
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default IndexPage
